import React, { useState } from 'react';
const crypto = require('crypto');

export default function PlotGame(props:any) {
  const {onNextGame}=props;
  const [value, setValue] = useState('');
  const [checking, setChecking] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const valueChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setShowWrong(false);
  };

  const clickChecked = () => {
    setChecking(true);
    setTimeout(()=>{
      setChecking(false);

      const hash = crypto.createHmac('sha256', 'esapekey')
                   .update(value.toLowerCase())
                   .digest('hex');

      console.log(hash);
      if (hash === 'da650c43bb71d12303a8c7d8d293d40dfaa7e51cf3ef48428b58cd2cd02e739c') {
        setShowWrong(()=>false);
        setShowSuccess(()=>true);
      } else {
        setShowWrong(true);
      }
    },1000)
  };

  return <>
  <pre>A1 B13 E6 A9 C1 B16 D12 A2 C13 A6 A18 B6 B17
     C11 D9 E1 E18 A13 E13 B1 B9 D17 E16 D6 C9 B10 E9 A16 B3 C2 D1 C6 D13 C16 D16</pre>
     <br/>
     { showSuccess ? <div>Yes! <button onClick={onNextGame}>Next Question</button></div> : 
     checking ? <div>Checking...</div>:
     <div>
       <div>
         The Answer is <input width={20} value={value} onChange={valueChanged}/>
         <button onClick={clickChecked}>Check</button>
        </div>
        {
          showWrong && <p style={{color: 'red'}}>Wrong</p>
        }
      </div>
      }
      </>
}

