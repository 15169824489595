import React, { useState } from 'react';
const crypto = require('crypto');

export default function WordNumbers(props:any) {
  const {onNextGame}=props;
  const [value, setValue] = useState('');
  const [checking, setChecking] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const valueChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setShowWrong(false);
  };

  const clickChecked = () => {
    setChecking(true);
    setTimeout(()=>{
      setChecking(false);

      const hash = crypto.createHmac('sha256', 'esapekey')
                   .update(value.toLowerCase())
                   .digest('hex');

      console.log(hash);
      if (hash === 'c55d455444c0c82145b2d272ad7baeaf413367886be519ad1ea15cefa7fb8c19') {
        setShowWrong(()=>false);
        setShowSuccess(()=>true);
      } else {
        setShowWrong(true);
      }
    },1000)
  };

  return <>
  <table>
    <tbody>
      <tr>
        <td>MELT</td>
        <td>423</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>8 SIDES</td>
        <td>321</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>NIMBUS</td>
        <td>234</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>H₂O</td>
        <td>514</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>WORD BEFORE HEN OR TERESA</td>
        <td>653</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>SKIN</td>
        <td>531</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>NOT LAST</td>
        <td>234</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>FLOWER FOUND IN EASTER</td>
        <td>341</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>NAP</td>
        <td>342</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>MALE GOOSE</td>
        <td>651</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      <tr>
        <td>OMEN</td>
        <td>31</td>
        <td><input type='text' style={{width: '32pt'}}></input></td>
      </tr>
      </tbody>
  </table>

     { showSuccess ? <div>Yes! <button onClick={onNextGame}>Next Question</button></div> : 
     checking ? <div>Checking...</div>:
     <div>
       <div>
         The Answer is <input width={20} value={value} onChange={valueChanged}/>
         <button onClick={clickChecked}>Check</button>
        </div>
        {
          showWrong && <p style={{color: 'red'}}>Wrong</p>
        }
      </div>
      }
      </>
}

