import React, { useState } from 'react';
import './ColourGrid.css';
const crypto = require('crypto');

export default function ColourGrid(props:any) {
  const {onNextGame}=props;
  const [value, setValue] = useState('');
  const [checking, setChecking] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const valueChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setShowWrong(false);
  };

  const clickChecked = () => {
    setChecking(true);
    setTimeout(()=>{
      setChecking(false);

      const hash = crypto.createHmac('sha256', 'esapekey')
                   .update(value.toLowerCase())
                   .digest('hex');

      console.log(hash);
      if (hash === '4ad7ceea1ec424894e24328ae5b23b776b91afa89e00dbf5d18bfb6720c54bc8') {
        setShowWrong(()=>false);
        setShowSuccess(()=>true);
      } else {
        setShowWrong(true);
      }
    },1000)
  };

  return <>
  <table id="colourGrid">
    <tbody>
      <tr><td>C</td><td>A</td><td>R</td><td>D</td><td>E</td><td>M</td><td>O</td><td>N</td><td>O</td><td>O</td><td>B</td><td>A</td><td>N</td><td>N</td><td>I</td><td>C</td></tr>
      <tr><td>N</td><td>O</td><td>B</td><td>O</td><td>D</td><td>Y</td><td>A</td><td>I</td><td>S</td><td>U</td><td>H</td><td>C</td><td>U</td><td>F</td><td>A</td><td>B</td></tr>
      <tr><td>H</td><td>O</td><td>R</td><td>L</td><td>O</td><td>W</td><td>I</td><td>E</td><td>N</td><td>I</td><td>C</td><td>E</td><td>N</td><td>T</td><td>E</td><td>R</td></tr>
      <tr><td>C</td><td>D</td><td>S</td><td>N</td><td>I</td><td>S</td><td>I</td><td>A</td><td>R</td><td>E</td><td>A</td><td>R</td><td>V</td><td>I</td><td>E</td><td>W</td></tr>
      <tr><td>A</td><td>E</td><td>F</td><td>R</td><td>E</td><td>D</td><td>A</td><td>N</td><td>E</td><td>V</td><td>A</td><td>L</td><td>E</td><td>A</td><td>N</td><td>I</td></tr>
      <tr><td>T</td><td>L</td><td>N</td><td>H</td><td>M</td><td>A</td><td>U</td><td>N</td><td>D</td><td>E</td><td>R</td><td>D</td><td>R</td><td>U</td><td>I</td><td>D</td></tr>
      <tr><td>N</td><td>P</td><td>E</td><td>R</td><td>S</td><td>I</td><td>W</td><td>I</td><td>N</td><td>K</td><td>L</td><td>E</td><td>M</td><td>U</td><td>R</td><td>E</td></tr>
      <tr><td>E</td><td>G</td><td>M</td><td>O</td><td>I</td><td>P</td><td>R</td><td>Q</td><td>J</td><td>A</td><td>R</td><td>S</td><td>E</td><td>K</td><td>A</td><td>N</td></tr>
      <tr><td>E</td><td>L</td><td>N</td><td>N</td><td>U</td><td>T</td><td>U</td><td>C</td><td>H</td><td>U</td><td>T</td><td>S</td><td>I</td><td>R</td><td>M</td><td>E</td></tr>
      <tr><td>G</td><td>U</td><td>R</td><td>B</td><td>U</td><td>R</td><td>G</td><td>R</td><td>U</td><td>N</td><td>D</td><td>Y</td><td>L</td><td>I</td><td>T</td><td>E</td></tr>
      <tr><td>A</td><td>T</td><td>A</td><td>F</td><td>F</td><td>Y</td><td>E</td><td>Y</td><td>E</td><td>V</td><td>A</td><td>C</td><td>I</td><td>A</td><td>A</td><td>L</td></tr>
      <tr><td>M</td><td>E</td><td>B</td><td>O</td><td>O</td><td>M</td><td>D</td><td>N</td><td>K</td><td>I</td><td>P</td><td>A</td><td>O</td><td>U</td><td>U</td><td>E</td></tr>
      <tr><td>A</td><td>N</td><td>Y</td><td>Z</td><td>E</td><td>W</td><td>O</td><td>R</td><td>A</td><td>N</td><td>G</td><td>T</td><td>N</td><td>I</td><td>Q</td><td>V</td></tr>
      <tr><td>N</td><td>E</td><td>X</td><td>E</td><td>S</td><td>I</td><td>O</td><td>U</td><td>C</td><td>Q</td><td>R</td><td>U</td><td>T</td><td>S</td><td>A</td><td>N</td></tr>
      <tr><td>X</td><td>E</td><td>S</td><td>O</td><td>P</td><td>R</td><td>U</td><td>P</td><td>A</td><td>E</td><td>V</td><td>R</td><td>E</td><td>E</td><td>S</td><td>E</td></tr>
      <tr><td>E</td><td>S</td><td>U</td><td>O</td><td>Y</td><td>S</td><td>E</td><td>K</td><td>A</td><td>T</td><td>S</td><td>I</td><td>M</td><td>E</td><td>H</td><td>T</td></tr>
    </tbody>
  </table>
  <br/>
  <div>(7,7,7,7,8,8,9,9,10,10)</div>
  <br/>
  { showSuccess ? <div>Yes! <button onClick={onNextGame}>Next Question</button></div> : 
     checking ? <div>Checking...</div>:
     <div>
       <div>
         The Answer is <input width={20} value={value} onChange={valueChanged}/>
         <button onClick={clickChecked}>Check</button>
        </div>
        {
          showWrong && <p style={{color: 'red'}}>Wrong</p>
        }
      </div>
      }


  </>;
}

