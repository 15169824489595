import React from "react";
import AnswerChecker from "../AnswerChecker";
import "./Table1.css";

const values = [
  "AAIW,19,LC,1865",
  "TGG,2,FSF,1925",
  "TKAM,2,HL,1960",
  "F451,13,RB,1953",
  "BNW,8,AH,1932",
  "WAP,3,LT,1869",
  "LOTF,2,WG,1954",
  "TCITR,6,JDS,1951",
  "TGOW,8,JS,1939",
  "206B,3,KR,2009",
  "AF,5,GO,1945",
  "IM,7,RE,1952",
  "GWTW,2,MM,1936",
  "TSAR,5,EH,1926",
  "WH,3,EB,1847",
  "1984,3,GO,1949",
  "OFOTCN,6,KK,1962",
  "MD,5,VW,1925",
  "OHYOS,15,GGM,1967",
  "GE,5,CD,1861",
  "AK,8,LT,1878",
  "ACO,9,AB,1962",
  "C22,6,JH,1961",
  "CAP,5,FD,1866",
  "SF,5,KV,1969",
  "TFOTR,22,JRRT,1954",
  "AGOT,14,GRRM,1996",
];

export default function Table1(props: any) {
  const { onNextGame } = props;

  return (
    <div>
      <table id="T1">
        <tbody>
          {values.map((row) => {
            return (
              <tr>
                {row.split(",").map((t) => {
                  return <td>{t}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <AnswerChecker
        onNextGame={onNextGame}
        correctAnswer="ee9d41a54bf5b15d32f5247a96b4c73835eca1c8a7e12f60fd8b31dcdfbc8b0d"
      />
    </div>
  );
}
