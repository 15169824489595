import React, { useState } from 'react';
import './ColourDir.css';
import AnswerChecker from '../AnswerChecker';
const crypto = require('crypto');

interface IColourDirProps {
  onNextGame: any
};

export const ColourDir = (props:IColourDirProps)=>{
  const {onNextGame} = props;

  return <div>
    <table id="arrowGrid">
      <tbody>
        <tr><td>1</td><td>⬇️ 6</td><td>➡️ 5</td><td>Yellow 7</td><td>➡️ 2</td><td>⬅️ 4</td><td>⬇️ 3</td><td>⬇️ 6</td></tr>
        <tr><td>2</td><td>Red 2</td><td>➡️ 3</td><td>⬇️ 3</td><td>➡️ 3</td><td>⬇️ 3</td><td>10</td><td>⬇️ 4</td></tr>
        <tr><td>➡️ 5</td><td>3</td><td>➡️ 2</td><td>0</td><td>⬆️ 2</td><td>⬇️ 3</td><td>⬇️ 4</td><td>11</td></tr>
        <tr><td>⬆️ 3</td><td>➡️ 3</td><td>⬆️ 2</td><td>⬅️ 3</td><td>⬇️ 3</td><td>Blue 3</td><td>➡️ 1</td><td>⬇️ 1</td></tr>
        <tr><td>➡️ 4</td><td>4</td><td>⬆️ 4</td><td>⬅️ 1</td><td>⬆️ 3</td><td>➡️ 1</td><td>⬆️ 2</td><td>6</td></tr>
        <tr><td>2</td><td>➡️ 3</td><td>➡️ 2</td><td>⬆️ 2</td><td>1</td><td>7</td><td>➡️ 3</td><td>⬅️ 2</td></tr>
        <tr><td>⬆️ 2</td><td>⬅️ 1</td><td>9</td><td>⬇️ 2</td><td>8</td><td>➡️ 4</td><td>⬅️ 3</td><td>⬅️ 3</td></tr>
        <tr><td>⬆️ 5</td><td>⬆️ 2</td><td>Pink 5</td><td>⬅️ 2</td><td>⬅️ 4</td><td>➡️ 1</td><td>⬆️ 2</td><td>⬅️ 2</td></tr>
      </tbody>
    </table>
    <br/>
    <pre>
    ____DEER <br/>
    ____GRASS  <br/>
    ____PANTHER  <br/>
    ____KNIFE <br/>
    </pre>
    
    <br/>

    <AnswerChecker onNextGame={onNextGame} correctAnswer="8707c268e84b759dd1f1ce32611f940f8aad262a2ad21bcc369e8c836ded7dca"/>
</div>;
}