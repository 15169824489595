import React, { useState } from "react";
const crypto = require("crypto");

export default function PlotGame(props: any) {
  const { onNextGame } = props;
  const [value, setValue] = useState("");
  const [checking, setChecking] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const valueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setShowWrong(false);
  };

  const clickChecked = () => {
    setChecking(true);
    setTimeout(() => {
      setChecking(false);

      const hash = crypto
        .createHmac("sha256", "esapekey")
        .update(value.toLowerCase())
        .digest("hex");

      console.log(hash);
      if (
        hash ===
        "fb77f0bf7341f1e40ed24f4a95c22e59e46014e97b3521ac2869964497b93b3f"
      ) {
        setShowWrong(() => false);
        setShowSuccess(() => true);
      } else {
        setShowWrong(true);
      }
    }, 1000);
  };

  return (
    <>
      <pre>
        10S 8H 1H 7S 9H 6S 7S 8H 5H 5H 1H 5S 12H 9H 5H 6S 7S 4H 1H 7S 5H 10S 5H
        8H 1H 9S 5H 8H 1H 4H 5H 1H 6S 7S 5H 5S
      </pre>
      {showSuccess ? (
        <div>
          Yes! <button onClick={onNextGame}>Next Question</button>
        </div>
      ) : checking ? (
        <div>Checking...</div>
      ) : (
        <div>
          <div>
            The Answer is{" "}
            <input width={20} value={value} onChange={valueChanged} />
            <button onClick={clickChecked}>Check</button>
          </div>
          {showWrong && <p style={{ color: "red" }}>Wrong</p>}
        </div>
      )}
    </>
  );
}
