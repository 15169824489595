
import React, { useState } from 'react';
const crypto = require('crypto');

interface IAnswerCheckerProps {
  onNextGame: any,
  correctAnswer: string
}

export default function AnswerChecker(props:IAnswerCheckerProps) {
  const {onNextGame, correctAnswer}=props;
  const [value, setValue] = useState('');
  const [checking, setChecking] = useState(false);
  const [showWrong, setShowWrong] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const valueChanged = (event:React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setShowWrong(false);
  };

  const clickChecked = () => {
    setChecking(true);
    setTimeout(()=>{
      setChecking(false);

      const hash = crypto.createHmac('sha256', 'esapekey')
                   .update(value.toLowerCase())
                   .digest('hex');

      console.log(hash);
      if (hash === correctAnswer) {
        setShowWrong(()=>false);
        setShowSuccess(()=>true);
      } else {
        setShowWrong(true);
      }
    },1000)
  };

  return <>
  { showSuccess ? <div>Yes! <button onClick={onNextGame}>Next Question</button></div> : 
     checking ? <div>Checking...</div>:
     <div>
       <div>
         The Answer is <input width={20} value={value} onChange={valueChanged}/>
         <button onClick={clickChecked}>Check</button>
        </div>
        {
          showWrong && <p style={{color: 'red'}}>Wrong</p>
        }
      </div>
      }
  </>;
}

