import React, { useState } from "react";
import "./App.css";
import PlotGame from "./games/PlotGame";
import Hands from "./games/Hands";
import RainbowEggs from "./games/RainbowEggs";
import WordNumbers from "./games/WordNumbers";
import ColourGrid from "./games/ColourGrid";
import { ColourDir } from "./games/ColourDir";
import Table1 from "./games/Table1";
import { HeadGame } from "./games/HeadGame";
import { BunnySquares } from "./games/BunnySquares";
import { Riddle } from "./games/Riddle";

function Intro(props: any) {
  const { onNextGame } = props;
  console.log(props);

  return (
    <div className="App" style={{ height: "100%" }}>
      <img style={{ width: "100%" }} src="/images/BunnyBanner.png" />
      <h1>Hoppy Easter!</h1>
      <p>Get it? Hoppy? Ha ha.</p>
      <p>
        Welcome to Easter in July! We're hoping to bring our bit of fun back to
        the world soon, and these puzzles are designed to get you back into
        solving.
      </p>
      <p>Keep track of your answers, you'll need them at the end.</p>
      <button onClick={onNextGame}>Start</button>
    </div>
  );
}

function Win() {
  return (
    <div>
      <h1>You got them all right!</h1>
      <div>
        Send an email to info@escapekey.ca with your 10 answers, and this code:
        MNQOOEEMNRO.
      </div>
    </div>
  );
}

const sequence = [
  "intro",
  "riddle",
  "bunnysquares",
  "head",
  "table1",
  "colourdir",
  "colourgrid",
  "wordnum",
  "rainbow",
  "plot",
  "hands",
  "win",
];

function App() {
  const [currentGame, setCurrentGame] = useState("intro");

  const onNextGame = () => {
    let idx = sequence.findIndex((e) => e === currentGame);
    if (idx !== -1) {
      const nextGame = sequence[idx + 1];
      setCurrentGame(nextGame);
    }
  };

  return (
    <>
      <div style={{ margin: "20px" }}>
        {currentGame === "intro" && <Intro onNextGame={onNextGame} />}
        {currentGame === "bunnysquares" && (
          <BunnySquares onNextGame={onNextGame} />
        )}
        {currentGame === "head" && <HeadGame onNextGame={onNextGame} />}
        {currentGame === "table1" && <Table1 onNextGame={onNextGame} />}
        {currentGame === "colourgrid" && <ColourGrid onNextGame={onNextGame} />}
        {currentGame === "colourdir" && <ColourDir onNextGame={onNextGame} />}
        {currentGame === "plot" && <PlotGame onNextGame={onNextGame} />}
        {currentGame === "wordnum" && <WordNumbers onNextGame={onNextGame} />}
        {currentGame === "hands" && <Hands onNextGame={onNextGame} />}
        {currentGame === "rainbow" && <RainbowEggs onNextGame={onNextGame} />}
        {currentGame === "riddle" && <Riddle onNextGame={onNextGame} />}
        {currentGame === "win" && <Win />}
      </div>
    </>
  );
}

export default App;
