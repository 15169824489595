import React, { useState } from 'react';
import './ColourDir.css';
import AnswerChecker from '../AnswerChecker';
const crypto = require('crypto');

interface IColourDirProps {
  onNextGame: any
};

export const HeadGame = (props:IColourDirProps)=>{
  const {onNextGame} = props;

  return <div>
    <table>
      <tbody>
        <tr>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">27</div>
            </span>
          </td>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">9</div>
            </span>
          </td>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">S</div>
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">🌡</div>
            </span>
          </td>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">🕘</div>
            </span>
          </td>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">🧭</div>
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">109</div>
            </span>
          </td>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">25</div>
            </span>
          </td>
          <td>
            <span className="headegg" style={{backgroundColor: 'hsl(10%,100%,50%)'}}>
              <div className="headeggSp">W</div>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    
    <br/>

    <p>Who is the ugly duckling?</p>
    
    <AnswerChecker onNextGame={onNextGame} correctAnswer="a8de2d16dbaaf6629525d92fab793ba540212fc5e841a5b77451411a46d21e78"/>
</div>;
}