import React, { useState } from 'react';


export default function RainbowEggs(props:any) {
  const {onNextGame}=props;
  const colors = [
    0, 40, 60, 120, 180, 210, 240, 270, 300, 330
  ]

  const remix = [
    1,7,5,0,2,6,4,8,9,3
  ];

  // const remix = [
  //   0,1,2,3,4,5,6,7,8,9
  // ];
  // Array of selected indexes, in order
  let [selection, setSelection] = useState(Array<number>());
  const [success, setSuccess] = useState(false);

  const toggleEgg = (eggNum:number)=>{
    if (success) {
      return;
    }

    const index = selection.indexOf(eggNum);
    if (index > -1) {
      selection.splice(index, 1);
    } else {
      selection.push(eggNum);
    }
    setSelection([...selection]);

    let str = selection.join('');
    if (str === '3049625178') {
      setSuccess(true);
    }
  }

  const eggNums:{[s: number]: number} = {};
  let idx = 0;
  selection.forEach(num=>{
    eggNums[num] = idx;
    idx = idx + 1;
  });

  return <>
    <div>
      { 
        colors.map((c,idx)=>{
          c = colors[remix[idx]];
          return <span key={idx} onClick={()=>toggleEgg(idx)} className="egg" style={{backgroundColor: 'hsl('+c+',100%,50%)'}}>
            <div className="eggSp">{eggNums[idx]!=null ? (eggNums[idx]+1) : ''}</div></span>;
          }
        )
      }
      { success && <div>Success! <button onClick={onNextGame}>Next Question</button></div> }
    </div>
  </>
}

