import React, { useState } from 'react';
import AnswerChecker from '../AnswerChecker';
const crypto = require('crypto');

interface IColourDirProps {
  onNextGame: any
};

export const BunnySquares = (props:IColourDirProps)=>{
  const {onNextGame} = props;

  return <div>
    <img src="images/bunnysquares.png"/>
    <br/>

    <p>How many bunnies are there?</p>
    
    <AnswerChecker onNextGame={onNextGame} correctAnswer="64565ab3a2cddb6908d76068f77e942821027dc6d81c4a4ae937e131d0bc71f5"/>
</div>;
}