import React, { useState } from 'react';
import AnswerChecker from '../AnswerChecker';
const crypto = require('crypto');

interface IRiddleProps {
  onNextGame: any
};

export const Riddle = (props:IRiddleProps)=>{
  const {onNextGame} = props;

  return <div>
    <em>
    Althochdeutsch<br/>
    <br/>
    Daughter of Heaven,<br/>
    Goddess of the Dawn.<br/>
    Bringer of Light,<br/>
    With hare and fawn.  
    </em>
    <br/>
    <br/>

    <AnswerChecker onNextGame={onNextGame} correctAnswer="fc6bb5cf0fc139ae44fce01a07c9808671e1753c11d8f675090a3464ec7ce9c6"/>
</div>;
}